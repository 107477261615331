/**
 * @prettier
 */

import { useState, useMemo } from 'react'
import { UIToggle } from 'talkable-ui-kit'

export const DynamicToggle = ({ path, checked, ...props }) => {
  const [isChecked, setIsChecked] = useState(checked)

  const handleToggleChange = useMemo(
    () => async e => {
      try {
        const response = await $.ajax({
          type: 'PUT',
          url: path,
        })
        const {
          result: { ttl, integration_request_logging },
        } = response.result
        const text_enabled = integration_request_logging ? 'enabled' : 'disabled'
        const ttl_date = new Date(ttl).toLocaleDateString()
        const ttl_text = ttl ? `It's active until ${ttl_date}` : ''
        const text = `Integration requests logging is ${text_enabled}. ${ttl_text}`
        const tooltip_text = ttl
          ? `The request logging will be automatically disabled on ${ttl_date}. If the current date is within 3 days of the disabling date, you are able to prolong the logging for an additional 2 weeks from today`
          : 'The feature is disabled'

        $('.js-prolong-btn').attr('data-original-title', tooltip_text)
        $('.js-prolong-btn').attr('data-title', tooltip_text)

        UINotifications.add({
          text,
          type: !isChecked ? 'success' : 'notice',
        })
        setIsChecked(prevState => !prevState)
      } catch (error) {
        UINotifications.add({
          text: _.values(_.get(error, 'responseJSON.result.errors', {})).join(' '),
          type: 'error',
          delay: 10_000,
        })
      }
    },
    [isChecked, path],
  )

  return <UIToggle {...props} checked={isChecked} onChange={handleToggleChange} />
}
