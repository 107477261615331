/**
 * @prettier
 */

import { useState, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { UIButton, UIIcon, Theme } from 'talkable-ui-kit'

import { delayForRetry } from 'utils/etc'

import { AddMetricsModal } from './add_metrics_modal'

const AddNewMetricButton = styled(UIButton)`
  text-transform: uppercase;
  margin-left: -16px;

  &:hover {
    svg * {
      stroke: ${Theme.color.firm};
    }
  }
`

interface IAddMetricsProps {
  additionalMetrics: IMetric[]
}

export interface IMetric {
  label: string
  value: string
}

export const AddMetrics: React.FC<IAddMetricsProps> = ({
  additionalMetrics,
  gridCollectionData,
}): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [selectedMetrics, setSelectedMetrics] = useState<IMetric[]>([])
  const [gridCollectionId, setGridCollectionId] = useState<null | number>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleRetryGetGrid = useCallback(
    (count): void => {
      const delay = delayForRetry(count)

      setTimeout(function () {
        $.get(
          Routes.status_site_reports_performance_path(Site.cached_slug, {
            gcid: gridCollectionId,
            no_spinner: true,
          }),
          function (data) {
            if (data.result.refresh_url) {
              window.location.href = data.result.refresh_url
            } else {
              handleRetryGetGrid(count + 1)
            }
          },
        ).fail(function (xhr) {
          setIsLoading(false)
          notifyDevelopers({
            name: 'Error during ping for getStatusExport',
            message: 'Ajax failure',
            xhr,
            custom: {
              retries: count,
              waiting_time_in_minutes: (count * 3) / 60,
            },
          })

          const errors = _.get(xhr, 'responseJSON.result.errors', {})
          const errorText = _.values(errors).join(' ')

          if (errorText) {
            UINotifications.add({
              text: errorText,
              type: 'error',
              delay: 10_000,
            })
          } else {
            handleRetryGetGrid(count + 1)
          }
        })
      }, delay)
    },
    [gridCollectionId],
  )

  useEffect(() => {
    if (gridCollectionId) {
      handleRetryGetGrid(0)
    }

    return (): void => {
      setGridCollectionId(null)
    }
  }, [gridCollectionId])

  const toggleModal = useCallback((): void => {
    setIsModalOpen(!isModalOpen)
  }, [isModalOpen, setIsModalOpen])

  const handleModalClose = useCallback((): void => {
    setIsModalOpen(false)
  }, [setIsModalOpen])

  const handleAddMetrics = useCallback((data): void => {
    setSelectedMetrics([...data.selectedOption])
  }, [])

  const onSave = useCallback((): void => {
    setIsLoading(true)

    const existingPeriods = gridCollectionData?.date_ranges
      ? new Set(gridCollectionData.date_ranges.map((_, index) => index + 1))
      : []
    const highlightValue =
      document.querySelector('input[name="g[highlight]"]')?.checked || false
    const compareData = Array.from(
      document.querySelectorAll('input[name^="g[compare]"]:checked'),
    ).reduce((acc, checkbox) => {
      const match = checkbox.name.match(/\[compare]\[(\d+)]/)

      if (match && match[1]) {
        const periodIndex = Number(match[1])

        if (existingPeriods.has(periodIndex)) {
          acc[match[1]] = 'on'
        }
      }

      return acc
    }, {})

    $.ajax({
      url: Routes.site_reports_performance_path(Site.cached_slug),
      type: 'POST',
      data: {
        g: {
          additional_metrics: selectedMetrics.map(metric => metric.value),
          highlight: highlightValue,
          compare: compareData,
          ...gridCollectionData?.date_ranges?.reduce((acc, range, index) => {
            acc[`date_range${index + 1}`] = range

            return acc
          }, {}),
        },
      },
      dataType: 'json',
      success: response => {
        if (response.result.grid_collection_id) {
          setGridCollectionId(response.result.grid_collection_id)
        }
      },
      error: error => {
        setIsLoading(false)
        UINotifications.add({
          text: error.responseJSON.result.errors,
          type: 'error',
          delay: 10_000,
        })
      },
    })
  }, [selectedMetrics])

  return (
    <>
      <AddNewMetricButton onClick={toggleModal} size="small" trait="transparent">
        <UIIcon
          className="mrs"
          name="plusInCircle"
          stroke={Theme.color.link}
          fill="white"
          width={16}
        />
        Add metrics
      </AddNewMetricButton>
      {isModalOpen && (
        <AddMetricsModal
          isLoading={isLoading}
          selectedMetrics={selectedMetrics}
          handleClose={handleModalClose}
          handleAddMetrics={handleAddMetrics}
          options={additionalMetrics}
          onSave={onSave}
        />
      )}
    </>
  )
}
