/**
 * @prettier
 */

import styled from 'styled-components'
import { UIIcon, H4, UILoader, H5 } from 'talkable-ui-kit'
import { useCallback, useState, useEffect } from 'react'

import { delayForRetry } from 'utils/etc'

interface PeriodToggleProps {
  active: boolean
  title: string
  token: string
  url: string
}

const Button = styled.button<{ active: boolean }>`
  width: 100%;
  padding: 16px;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  background-color: ${(props): string => (props.active ? '#f3ae3f29' : '#f8f8f8')};
  border: none;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.2s;
  margin-bottom: ${(props): string => (props.active ? '30px' : '10px')};

  &:hover {
    background-color: #f3ae3f29;
  }

  & > span {
    font-size: 12px;
    color: #888;
  }
`

const ButtonArrow = styled(UIIcon)`
  transform: rotate(${(props): string => (props.active ? '180deg' : '0deg')});
`

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`

export const PeriodToggle: React.FC<PeriodToggleProps> = ({
  active,
  title,
  url,
  token,
  gridCollectionData,
  identifier,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [gridExportId, setGridExportId] = useState(null)
  const [isActive, setIsActive] = useState(active)

  const handleRetryGetGrid = useCallback(
    (count): void => {
      const delay = delayForRetry(count)

      setTimeout(function () {
        $.get(
          Routes.grid_export_status_site_reports_performance_path(Site.cached_slug, {
            gcid: gridCollectionData.id,
            geid: gridExportId,
            no_spinner: true,
          }),
          function (data) {
            if (data.result.refresh_url) {
              setIsLoading(false)
              window.location.href = data.result.refresh_url
            } else {
              handleRetryGetGrid(count + 1)
            }
          },
        ).fail(function (xhr) {
          setIsLoading(false)
          notifyDevelopers({
            name: 'Error during ping for getStatusExport',
            message: 'Ajax failure',
            xhr,
            custom: {
              grid_export: url,
              retries: count,
              waiting_time_in_minutes: (count * 3) / 60,
            },
          })

          const errors = _.get(xhr, 'responseJSON.result.errors', {})
          const errorText = _.values(errors).join(' ')

          if (errorText) {
            UINotifications.add({
              text: errorText,
              type: 'error',
              delay: 10_000,
            })
          } else {
            handleRetryGetGrid(count + 1)
          }
        })
      }, delay)
    },
    [gridCollectionData.id, url, gridExportId],
  )

  useEffect(() => {
    if (gridExportId) {
      handleRetryGetGrid(0)
    }

    return (): void => {
      setGridExportId(null)
    }
  }, [gridExportId, handleRetryGetGrid])

  const handleClick = useCallback((): void => {
    if (!active) {
      setIsLoading(true)
      setIsActive(true)
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': token,
        },
      })
        .then(response => response.json())
        .then(data => {
          if (data.result.grid_export_id) {
            setGridExportId(data.result.grid_export_id)
          }
        })
        .catch(error => {
          console.log('error', error)
          setIsActive(false)
        })
    } else {
      setIsActive(!isActive)

      $(`#${identifier}`).toggleClass('hidden')
    }
  }, [url, token, active, isActive, identifier])

  return (
    <Button active={isActive} onClick={handleClick}>
      {isLoading ? (
        <UILoader />
      ) : (
        <>
          <TitleContainer>
            <H4>{title}</H4>
            <H5 className="mlm">Period #1</H5>
            <H5 className="mls">{gridCollectionData.date_ranges[0][0]}</H5>
            <H5 className="mls">-</H5>
            <H5 className="mls">{gridCollectionData.date_ranges[0][1]}</H5>
          </TitleContainer>
          <ButtonArrow active={isActive} name="downArrow" fill="#0000004D" />
        </>
      )}
    </Button>
  )
}
