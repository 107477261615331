/**
 * @prettier
 */

/* eslint-disable no-template-curly-in-string */
import { useEffect, useRef } from 'react'
import styled from 'styled-components'
import * as Monaco from 'monaco-editor'

const Editor = styled.div`
  width: 100%;
  height: 100%;
`

export const MonacoEditor = ({ type, data, language, theme }) => {
  const containerRef = useRef()

  useEffect(() => {
    Monaco.editor.defineTheme('talkable', {
      base: 'vs',
      inherit: true,
      rules: [
        { token: 'predefined.liquid', foreground: '859900' },
        { token: 'string.liquid', foreground: '2AA198' },
        { token: 'delimiter.output.liquid', foreground: '268BD2' },
        { token: 'delimiter.tag.liquid', foreground: '268BD2' },
      ],
      colors: {
        'editor.lineHighlightBackground': '#faf8de',
      },
    })

    const editor = Monaco.editor.create(containerRef.current, {
      value: data,
      language,
      theme: 'talkable',
      wordWrap: 'on',
      minimap: {
        enabled: false,
      },
      wrappingIndent: 'indent',
    })

    editor.onDidChangeModelContent(event => {
      event.changes.forEach(change => {
        // default liquid auto closing pairs -> %%
        if (change.text === '%%') {
          const position = change.range.getStartPosition()
          const lineContent = editor.getModel().getLineContent(position.lineNumber)
          const context = Widget.Editor.Monaco.determineContext(lineContent, position)

          if (context === 'HTML') {
            const selection = editor.getSelection()
            const model = editor.getModel()
            let range = selection

            if (selection.isEmpty()) {
              const lineCount = model.getLineCount()
              const maxColumn = model.getLineMaxColumn(selection.startLineNumber)

              if (selection.startColumn < maxColumn) {
                range = new Monaco.Range(
                  selection.startLineNumber,
                  selection.startColumn,
                  selection.endLineNumber,
                  selection.endColumn + 1,
                )
              } else if (selection.startLineNumber < lineCount) {
                range = new Monaco.Range(
                  selection.startLineNumber,
                  selection.startColumn,
                  selection.startLineNumber + 1,
                  1,
                )
              }
            }

            editor.executeEdits('', [
              {
                range,
                text: null,
              },
            ])
          }
        }
      })
    })

    Widget.Editor.Monaco.instances[type] = editor
    Widget.Editor.Monaco.originalData[type] = data
  }, [data, language, type])

  return <Editor ref={containerRef} />
}
