/**
 * @prettier
 */

import styled from 'styled-components'
import { UIModal, UIIcon, UIToggle, H2, BodyS, BodyM, BodyD } from 'talkable-ui-kit'
import { useEffect, useState, useCallback } from 'react'

import { delayForRetry } from 'utils/etc'

interface Props {
  name: string
}

const IconContainer = styled.div`
  margin-bottom: 20px;
`

const Title = styled(H2)`
  margin-bottom: 10px;
`

const Subtitle = styled(BodyS)`
  margin-bottom: 86px;
`

const EmailContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 6px;
  border-top: 1px solid #e8e8e8;
  padding-top: 10px;
  width: 100%;
`

const EmailText = styled(BodyM)`
  margin-right: 10px;
`

const Note = styled(BodyD)`
  text-align: left;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 64px;
`

export const GeneratingReportModal: React.FC<Props> = () => {
  const [isVisible, setIsVisible] = useState(false)
  const [notifyActive, setNotifyActive] = useState(false)
  const [gridCollectionId, setGridCollectionId] = useState(null)

  const handleRetryGetUrl = useCallback(
    (count): void => {
      const delay = delayForRetry(count)

      setTimeout(function () {
        $.get(
          Routes.status_site_reports_performance_path(Site.cached_slug, {
            gcid: gridCollectionId,
            no_spinner: true,
          }),
          function (data) {
            if (data.result.refresh_url) {
              window.location.href = data.result.refresh_url
            } else {
              handleRetryGetUrl(count + 1)
            }
          },
        ).fail(function (xhr) {
          notifyDevelopers({
            name: 'Error during ping for getStatusExport',
            message: 'Ajax failure',
            xhr,
            custom: {
              grid_export: window.location.href,
              retries: count,
              waiting_time_in_minutes: (count * 3) / 60,
            },
          })

          const errors = _.get(xhr, 'responseJSON.result.errors', {})
          const errorText = _.values(errors).join(' ')

          if (errorText) {
            UINotifications.add({
              text: errorText,
              type: 'error',
              delay: 10_000,
            })
          } else {
            handleRetryGetUrl(count + 1)
          }
        })
      }, delay)
    },
    [gridCollectionId],
  )

  const handleNotifyByEmail = useCallback((): void => {
    $.ajax({
      url: Routes.toggle_notify_site_reports_performance_path(Site.cached_slug, {
        gcid: gridCollectionId,
        no_spinner: true,
      }),
      type: 'PATCH',
      dataType: 'json',
      success: () => {
        UINotifications.add({
          text: 'You will receive an email when the report is ready.',
          type: 'success',
          delay: 10_000,
        })
      },
      error: () => {
        setNotifyActive(false)
        UINotifications.add({
          text: 'An error occurred while trying to notify you by email.',
          type: 'error',
          delay: 10_000,
        })
      },
    })
  }, [gridCollectionId])

  const handleNotifyActive = useCallback(
    ({ target: { value } }): void => {
      setNotifyActive(value)
      handleNotifyByEmail()
    },
    [handleNotifyByEmail],
  )

  useEffect(() => {
    const buttons = document.querySelectorAll('.js-generate-report-modal-trigger')

    const handleOpenModal = (): void => {
      setIsVisible(true)
    }

    buttons.forEach(button => {
      button.addEventListener('click', handleOpenModal)
    })

    return (): void => {
      buttons.forEach(button => {
        button.removeEventListener('click', handleOpenModal)
      })
    }
  }, [])

  useEffect(() => {
    $(document).on('ajax:success', '.filter-form', function (event, data, status, xhr) {
      if (data.result.grid_collection_id) {
        setGridCollectionId(data.result.grid_collection_id)
      }
    })

    return (): void => {
      $(document).off('ajax:success', '.filter-form')
    }
  }, [])

  useEffect(() => {
    if (gridCollectionId) {
      handleRetryGetUrl(1)
    }
  }, [gridCollectionId, handleRetryGetUrl])

  return (
    isVisible && (
      <UIModal
        position="center"
        actionClose={(): void => setIsVisible(false)}
        height="388px"
        width="388px"
      >
        <Container>
          <IconContainer>
            <UIIcon width={50} height={50} name="hornWithCross" />
          </IconContainer>
          <Title>Generating report...</Title>
          <Subtitle>
            Your report was queued. Please note that this process may take some time:
          </Subtitle>
          <EmailContainer>
            <EmailText>Email me the report when ready</EmailText>
            <UIToggle
              checked={notifyActive}
              disabled={notifyActive || !gridCollectionId}
              onChange={handleNotifyActive}
            />
          </EmailContainer>
          <Note>
            The report will be emailed to {current_user.email} when it is ready.
            <br />
            You can close this popup now.
          </Note>
        </Container>
      </UIModal>
    )
  )
}
