import { useCallback } from "react"
import styled from 'styled-components'
import { UIButton } from 'talkable-ui-kit'

const BackButton = styled(UIButton)`
  position: absolute;
  top: 6.5px;
  right: 15px;
  z-index: 1000;
`

export const PartialBackButton = ({ type }) => {
  const changeClassNames = (originClass, additionalClass, add = true) => {
    if (add) {
      document.querySelector(originClass)?.classList.add(additionalClass)
    } else {
      document.querySelector(originClass)?.classList.remove(additionalClass)
    }
  }

  const onClickHandler = useCallback(() => {
    Widget.Editor.Monaco.instances[type].setValue(Widget.Editor.Monaco.originalData[type])
    Widget.Editor.initData()
    Widget.Editor.Monaco.instances[type].setPosition(Widget.Editor.Monaco.lastInstanceCursorPosition[type])
    Widget.Editor.Monaco.instances[type].revealPositionInCenter(Widget.Editor.Monaco.lastInstanceCursorPosition[type])

    if (type === 'css') {
      changeClassNames('.js-panel-css', 'hidden', false)
    } else if (type === 'html') {
      changeClassNames('.editor-wrapper-top', 'editor-wrapper-top-custom', false)
      Widget.Editor.Monaco.instances[type].layout()
    }

    changeClassNames(`.js-partial-panel-${type}`, 'hidden')
    sessionStorage.removeItem(`current-partial-${type}`)
  }, [type])

  return (
    <BackButton
      size="smaller"
      className={`back-to-original-${type} hidden`}
      onClick={onClickHandler}
    >
      Show original template
    </BackButton>
  )
}
