/**
 * @prettier
 */

import { useCallback } from 'react'
import styled from 'styled-components'
import { H4 } from 'talkable-ui-kit'

const Wrapper = styled.div`
  margin-left: 20px;
  cursor: pointer;

  & * {
    font-size: 14px !important;
  }

  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-decoration-color: grey;
  text-underline-offset: 2px;
`

export const ClearAllButton: React.FC = () => {
  const handleClearAll = useCallback(() => {
    const currentPath = window.location.pathname

    window.location.assign(currentPath)
  }, [])

  return (
    <Wrapper onClick={handleClearAll}>
      <H4>Clear all</H4>
    </Wrapper>
  )
}
