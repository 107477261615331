/**
 * @prettier
 */

import PT from 'prop-types'
import styled from 'styled-components'

import { MainHeaderUserMenu } from './header_user_menu'
import { MainHeaderStaffMenu, StaffMenuIconWrapper } from './header_staff_menu'

const HeaderMenuContainer = styled.div`
  ${StaffMenuIconWrapper} {
    height: 30px;
    transform: translateY(-2px);
  }
`

export class EditorHeaderMenu extends React.PureComponent {
  static propTypes = {
    site: PT.object.isRequired,
    account: PT.object.isRequired,
    currentUser: PT.object.isRequired,
  }

  render() {
    const { site, account, currentUser } = this.props
    const isStaffMember = currentUser?.staff_member
    const isPermissionManager = currentUser?.permission_manager

    return (
      <HeaderMenuContainer>
        <MainHeaderUserMenu
          editor
          site={site}
          account={account}
          currentUser={currentUser}
        />
        {(isStaffMember || isPermissionManager) && (
          <MainHeaderStaffMenu isPermissionManager={isPermissionManager} />
        )}
      </HeaderMenuContainer>
    )
  }
}
