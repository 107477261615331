/**
 * @prettier
 */

import pluralize from 'pluralize'
import { UIModal, BodyM, UIInputSelect, UIButton } from 'talkable-ui-kit'

import { IMetric } from './add_metrics'

interface IAddMetricsModalProps {
  handleAddMetrics: (data: any) => void
  handleClose: () => void
  isLoading: boolean
  onSave: () => void
  options: IMetric[]
  selectedMetrics: IMetric[]
}

export const AddMetricsModal: React.FC<IAddMetricsModalProps> = ({
  handleClose,
  selectedMetrics,
  handleAddMetrics,
  options,
  onSave,
  isLoading,
}): JSX.Element => {
  return (
    <UIModal
      actionClose={handleClose}
      height="500px"
      ribbon="no selected"
      title="Add new metrics"
      position="center"
    >
      <div>
        <BodyM>
          Select the metrics in the list below to add them to the report. You can add
          multiple metrics at once.
        </BodyM>
        <UIInputSelect
          className="mtm"
          onChange={handleAddMetrics}
          options={options}
          placeholder="Start typing to add metric"
          value={selectedMetrics}
          multi
        />
        <UIButton
          disabled={!selectedMetrics.length || isLoading}
          className="mtxxl"
          trait="success"
          size="small"
          onClick={onSave}
        >
          {`Add ${pluralize('metric', selectedMetrics.length)}`}
        </UIButton>
      </div>
    </UIModal>
  )
}
