/**
 * @prettier
 */

import { useState, useCallback } from 'react'
import styled from 'styled-components'
import { UIRangePicker, UIIcon, H4, UIInputCheckbox, UIStaffBadge } from 'talkable-ui-kit'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: -25px -30px 25px -30px;
  border-bottom: 1px solid #cccccc;
`
const PeriodWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 18px 30px;
  min-height: 68px;
  box-sizing: border-box;
  :nth-child(odd) {
    background-color: rgba(44, 113, 210, 0.05);
  }
`

const AddNewButton = styled.button`
  display: flex;
  align-items: center;
  background: none;
  border: none;
  color: ${({ disabled }) => (disabled ? '#ccc' : '#000')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  font-size: 16px;
  font-weight: 300;
  opacity: ${({ disabled }) => (disabled ? '0.6' : '1')};
`

const PickerWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
`

const DeleteIcon = styled(UIIcon)`
  cursor: pointer;
`

export const ReportPeriods = ({ gridCollectionData, isStaff }) => {
  const [periods, setPeriods] = useState(
    gridCollectionData?.date_ranges?.map((_, index) => index) || [0, 1],
  )
  const [checkedIds, setCheckedIds] = useState(gridCollectionData?.compare || [])
  const [differenceCheckboxActive, setDifferenceCheckboxActive] = useState(
    gridCollectionData?.highlight || false,
  )

  const handleAddPeriod = useCallback(() => {
    setPeriods(prev => [...prev, prev.length])
  }, [])

  const handleRemovePeriod = useCallback(indexToRemove => {
    setPeriods(prev => prev.filter((_, i) => i !== indexToRemove))
    setCheckedIds(prev => prev.filter(id => id !== indexToRemove))
  }, [])

  const handleCheckboxChange = id => {
    if (checkedIds.includes(id)) {
      setCheckedIds(checkedIds.filter(item => item !== id))
    } else {
      setCheckedIds([...checkedIds, id])
    }
  }

  const handleDifferenceCheckboxChange = useCallback(() => {
    setDifferenceCheckboxActive(!differenceCheckboxActive)
  }, [differenceCheckboxActive])

  return (
    <Wrapper>
      {periods.map((_, index) => {
        const itemNumber = index + 1
        const defaultValues = {
          date_range1: index ? dayjs().subtract(2, 'week') : dayjs().subtract(1, 'week'),
          date_range2: index ? dayjs().subtract(1, 'week') : undefined,
        }
        const inputProps = [
          {
            name: `g[date_range${itemNumber}][]`,
            value: dayjs(
              gridCollectionData?.date_ranges?.[index]?.[0] ?? defaultValues.date_range1,
            ).format('YYYY-MM-DD'),
          },
          {
            name: `g[date_range${itemNumber}][]`,
            value: dayjs(
              gridCollectionData?.date_ranges?.[index]?.[1] ?? defaultValues.date_range2,
            ).format('YYYY-MM-DD'),
          },
        ]

        return (
          <PeriodWrapper key={index}>
            <PickerWrapper>
              <UIRangePicker inputProps={inputProps} />
              <H4 className="mhm">Period {`${itemNumber}`}</H4>
              {periods.length > 2 && (
                <DeleteIcon
                  className="mrs"
                  name="delete"
                  width={14}
                  onClick={() => handleRemovePeriod(index)}
                />
              )}
            </PickerWrapper>
            <UIInputCheckbox
              size="default"
              id={`compare-${index}`}
              labelText="Compare"
              name={`g[compare][${itemNumber}]`}
              disabled={checkedIds.length === 2 && !checkedIds.includes(index)}
              checked={checkedIds.includes(index)}
              onChange={() => {
                handleCheckboxChange(index)
              }}
            />
          </PeriodWrapper>
        )
      })}

      <PeriodWrapper>
        <PickerWrapper>
          <div
            aria-label={
              periods.length >= 5
                ? 'You can add up to 5 periods for comparison. If you want to add a new period, please replace one of the existing periods.'
                : undefined
            }
            data-balloon-pos="up"
            data-balloon-length="large"
          >
            <AddNewButton
              type="button"
              onClick={handleAddPeriod}
              disabled={periods.length >= 5}
            >
              <UIIcon
                className="mrs"
                name="plusInCircle"
                stroke="grey"
                fill="white"
                width={16}
              />
              Add new date range
            </AddNewButton>
          </div>
        </PickerWrapper>

        {isStaff && (
          <>
            <UIInputCheckbox
              size="default"
              id="highlight-difference"
              labelText="Highlight difference in stats"
              checked={differenceCheckboxActive}
              name="g[highlight]"
              value={differenceCheckboxActive}
              onChange={handleDifferenceCheckboxChange}
            />
            <UIStaffBadge className="mlm" size="micro" text={false} />
          </>
        )}
      </PeriodWrapper>
    </Wrapper>
  )
}
